import React, { useContext, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { isBrowser } from 'utils/browser';
import Layout from 'components/Layout';
import { useLocation } from '@reach/router';

import { useMutation, useQuery } from '@apollo/client';
import { OrganizationDashboard } from 'tutadoo-design-system/src/components';
import TabItems, {
  getFilterVars
} from 'tutadoo-design-system/src/components/OrganizationDashboard/TabItems';
import { navigate } from 'gatsby';
import slugify from 'tutadoo-design-system/src/utils/slugify';
import {
  ICreateOrganizationPayload,
  IOrganization,
  IPayoutMethod,
  OrganizationService
} from 'services/organizations';
import { IEvent } from 'tutadoo-design-system/src/services/events';
import useToast from 'tutadoo-design-system/src/hooks/useToast';
import { AuthContext } from 'contexts/AuthContext';
import { useApi } from 'providers/ApiProvider';
import { UploadApi } from 'services/api';

const tabs = {
  events: {
    label: 'Events',
    component: ({ user }) => {
      const resp = useQuery<
        { events: IEvent[] },
        { status?: { _eq: string } | {}; date?: { _lte: string } | {} }
      >(OrganizationService.getAllUsersEvents(user?.sub), {
        variables: getFilterVars(''),
        notifyOnNetworkStatusChange: true
      });

      return (
        <TabItems.Events
          data={resp?.data}
          loading={resp?.loading}
          refetch={(v) => {
            resp.refetch(v);
          }}
          createEventURL="/host/event/create/"
          onViewEventDashboard={({ id }) => navigate(`/host/event/dashboard/${id}/home/`)}
          onViewEvent={({ name, id }) =>
            navigate(`/event/${encodeURIComponent(slugify(name))}/${id}/`)
          }
          onPreviewEvent={({ name, id }) =>
            navigate(`/event/${encodeURIComponent(slugify(name))}/${id}/`)
          }
        />
      );
    }
  },
  info: {
    label: 'Basic Info',
    component: ({ organization }) => {
      const { api } = useApi();
      const uploadAPI = useMemo(() => new UploadApi(api), [api]);
      const [updateOrganization, { loading }] = useMutation<
        { update_organizations_by_pk: IOrganization },
        { set: ICreateOrganizationPayload; orgId: string }
      >(OrganizationService.updateOrgById);
      const { renderToast, toastify } = useToast();

      const handleSave = (value: IOrganization) => {
        delete value?.__typename;
        delete value?.id;
        delete value?.image;
        delete value?.created_at;

        updateOrganization({ variables: { set: value, orgId: organization?.id } })
          .then(() => {
            toastify({
              isVisible: true,
              title: 'Updated successfully',
              message: '',
              status: 'normal'
            });
          })
          .catch((err) => {
            toastify({
              isVisible: true,
              title: 'Failed to update organization',
              message: err?.message,
              status: 'critical'
            });
          });
      };
      return (
        <TabItems.BasicInfo
          organization={organization}
          onSave={handleSave}
          loading={loading}
          onUpload={(file) => uploadAPI.upload(file)}
        />
      );
    }
  },
  // members: {
  //   label: 'Members',
  //   component: TabItems.Members,
  // },
  payouts: {
    label: 'Payouts',
    component: ({ organization }) => {
      const { renderToast, toast, renderLoader } = useToast();
      const organizationID = organization?.id;

      const [addPayoutMethod, { loading: addLoading }] = useMutation<
        { insert_organization_account_one: IPayoutMethod },
        { newPayout: IPayoutMethod }
      >(OrganizationService.addPayoutMethod, {
        update(store, { data }) {
          const payoutData = store.readQuery<{ payoutMethods: IPayoutMethod[] }>({
            query: OrganizationService.getAllPayouts(organizationID)
          });
          store.writeQuery<{ payoutMethods: IPayoutMethod[] }>({
            query: OrganizationService.getAllPayouts(organizationID),
            data: {
              payoutMethods: [...payoutData?.payoutMethods, data.insert_organization_account_one]
            }
          });
        }
        // onCompleted: () => onClose()
      });

      const onAddPayout = (value: IPayoutMethod) => {
        const newPayout = { ...value, organization_id: organizationID };
        addPayoutMethod({ variables: { newPayout } })
          .then(() => {
            toast.success('New Payout method added succesfully');
          })
          .catch((err) => {
            toast.error(err?.message, 'Failed to add payout method');
          });
      };

      const { loading: loadingPayouts, data } = useQuery<{ payoutMethods: IPayoutMethod[] }>(
        OrganizationService.getAllPayouts(organizationID)
      );

      const [deletePayout, { loading }] = useMutation<
        { payoutMethod: IPayoutMethod },
        { payoutId: string }
      >(OrganizationService.deletePayout, {
        variables: { payoutId: null },
        onError: (error) => toast.error(error?.message),
        update(store, { data }) {
          const payoutData = store.readQuery<{ payoutMethods: IPayoutMethod[] }>({
            query: OrganizationService.getAllPayouts(organizationID)
          });

          store.writeQuery<{ payoutMethods: IPayoutMethod[] }>({
            query: OrganizationService.getAllPayouts(organizationID),
            data: {
              payoutMethods: payoutData?.payoutMethods.filter(
                (pm) => pm.id !== data.payoutMethod.id
              )
            }
          });
        }
      });

      const [updatePayout, { loading: updating }] = useMutation<
        { payoutMethod: IPayoutMethod },
        {
          payoutId: string;
          set: IPayoutMethod;
        }
      >(OrganizationService.updatePayout, {
        // onCompleted: () => console,
        onError: (err) => toast.error(err?.message)
      });

      return (
        <>
          <TabItems.Payouts
            data={data}
            organization={organization}
            onAddPayout={onAddPayout}
            onDeletePayout={(id) => deletePayout({ variables: { payoutId: id } })}
            onUpdatePayout={updatePayout}
            updating={updating}
            loading={loading || loadingPayouts || addLoading}
          />
          {renderToast()}
          {renderLoader(updating, 'Adding New Payout Method...')}
        </>
      );
    }
  }
};

const Dashboard = () => {
  if (!isBrowser) return null;
  const { loginWithRedirect, isAuthenticated, isLoading, user: authUser } = useAuth0();
  if (!isLoading && isAuthenticated === false) {
    loginWithRedirect();
    return null;
  }
  const authContext = useContext(AuthContext);
  const { pathname } = useLocation();
  const pathUnsplit = pathname.split('/');
  const tab = pathUnsplit.filter((v) => v.length > 0).slice(-1);
  const organization = {
    id: authUser?.sub,
    name: authUser?.nickname,
    email: authUser?.email,
    image_url: authUser?.picture,
    ...(authContext?.user?.userInfo?.main_organizations?.length > 0
      ? authContext.user.userInfo.main_organizations[0]
      : {})
  };
  return (
    <div className="lg:w-10/12 mx-auto">
      <OrganizationDashboard
        basepath="/host/dashboard"
        tab={tab[0]}
        user={authUser}
        organization={organization}
        navigate={(v) => {}}
        onClickTab={({ key }) => navigate(`/host/dashboard/${key}/`)}
        tabs={tabs}
      />
    </div>
  );
};

const HostDashboard = (props) => (
  <Layout>
    <Dashboard {...props} />
  </Layout>
);

export default HostDashboard;
